<template>
  <v-dialog v-model="dialogAssignRoleUsers" max-width="600px" persistent>
    <v-card>
      <v-card-title class="tw-text-xs tw-text-slate-600"
        >Assign Users to {{ role.roleDisplayName }} Role(s)</v-card-title
      >
      <v-card-text>
        <v-autocomplete
          chips
          clearable
          deletable-chips
          multiple
          small-chips
          v-model="selectedUsers"
          :items="users"
          label="Users"
          :item-text="
            (item) =>
              `${item.firstName ? item.firstName : item.email} ${item.lastName}`
          "
          item-value="id"
          hide-selected
          prepend-icon="mdi-account-multiple"
        />
        <v-autocomplete
          chips
          clearable
          deletable-chips
          multiple
          small-chips
          v-model="selectedRoles"
          :items="roles"
          v-if="isRoleEmpty()"
          item-text="roleName"
          label="Roles"
          item-value="uuid"
          hide-selected
          prepend-icon="mdi-account-multiple"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="closeAssignUsers"
          >Cancel</v-btn
        >
        <v-btn
          color="success darken-1"
          text
          @click="assignRoleUsersConfirm"
          v-if="!isRoleEmpty()"
          >OK</v-btn
        >
        <v-btn
          color="success darken-1"
          text
          @click="assignUsersToRolesConfirm"
          v-else-if="isRoleEmpty()"
          >SAVE</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  ref: "assignRolesUsersDialog",
  props: ["role", "roles"],
  data: function () {
    return {
      selectedUsers: [],
      selectedRoles: [],
    };
  },
  computed: {
    users() {
      return this.$store.getters.getUsers;
    },
    dialogAssignRoleUsers: {
      get() {
        return this.$store.getters.getDialogAssignRoleUsers;
      },
      set(value) {
        this.$store.commit("setDialogAssignRoleUsers", value);
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("loadUsers");
    this.selectedUsers = [];
  },
  methods: {
    isRoleEmpty() {
      return JSON.stringify(this.role) === "{}";
    },
    closeAssignUsers() {
      (this.selectedUsers = []),
        (this.selectedRoles = []),
        (this.dialogAssignRoleUsers = false);
    },
    async assignRoleUsersConfirm() {
      await this.$store
        .dispatch("addRoleToUser", {
          roleUuids: [this.role.uuid],
          userUuids: this.selectedUsers,
        })
        .then(() => {
          this.closeAssignUsers();
        });
    },
    async assignUsersToRolesConfirm() {
      // console.log(this.selectedUsers);
      // console.log(this.selectedRoles);
      await this.$store
        .dispatch("addRoleToUser", {
          roleUuids: this.selectedRoles,
          userUuids: this.selectedUsers,
        })
        .then(() => {
          this.closeAssignUsers();
        });
    },
  },
};
</script>

<style></style>
