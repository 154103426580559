<template>
  <v-card :class="['mx-auto mt-5 tw-w-[90%]']">
    <v-card-title>
      <v-btn
        color="primary"
        @click="openCreateRoleDialog()"
        elevation="0"
        class="rounded-md text-capitalize"
        small
      >
        <v-icon small>mdi-plus</v-icon>&nbsp; Create Role
      </v-btn>
      <CreateRole :DialogTitle="dialogTitle" :EditItemData="editItemData" />
      <AssignRoleUsersDialog :role="role" :roles="roles" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-card-text>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5 tw-text-slate-600"
            >Are you sure you want to delete {{ this.role.roleName }} Role?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="red darken-1" text @click="deleteItemConfirm"
              >Delete</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table :headers="headers" :items="roles" :search="search">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Roles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="openAssignRolesDialog()"
              elevation="0"
              class="rounded-md text-capitalize"
              small
            >
              <v-icon small>mdi-account-lock</v-icon>&nbsp;Assign Roles
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.sn="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu bottom dense>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" v-on="on">
                <v-icon small>mdi-hammer-screwdriver</v-icon>
              </v-btn>
            </template>

            <v-list dense class="min-width: 120px">
              <v-list-item @click="showItem(item)">
                <v-list-item-title
                  ><v-icon small>mdi-eye</v-icon> View</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="editItem(item)">
                <v-list-item-title
                  ><v-icon small>mdi-pencil</v-icon> Edit</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="deleteItem(item)">
                <v-list-item-title
                  ><v-icon small>mdi-delete</v-icon> Delete</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="assignRoleUsers(item)">
                <v-list-item-title
                  ><v-icon small>mdi-account-plus</v-icon> Assign
                  users</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data>
          <p class="my-3">NO DATA FOUND</p>
          <v-btn small color="primary" @click="initialize" class="mb-3">
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CreateRole from "@/components/RoleManagement/create.vue";
import AssignRoleUsersDialog from "@/components/RoleManagement/AssignRoleUsersDialog.vue";
export default {
  name: "role-view",
  data() {
    return {
      search: "",
      dialogTitle: null,
      editItemData: null,

      dialogDelete: false,
      role: {},
      headers: [
        {
          text: "S/N",
          align: "start",
          filterable: false,
          value: "sn",
        },
        { text: "Role Name", value: "roleName" },
        { text: "Description", value: "roleDescription" },
        { text: "Actions", filterable: false, value: "actions" },
      ],
    };
  },
  components: {
    CreateRole,
    AssignRoleUsersDialog,
  },
  computed: {
    roles() {
      return this.$store.getters.getRoles;
    },
    createRoleBool: {
      get() {
        return this.$store.getters.getCreateRoleBool;
      },
      set(value) {
        this.$store.commit("setCreateRoleBool", value);
      },
    },
    dialogAssignRoleUsers: {
      get() {
        return this.$store.getters.getDialogAssignRoleUsers;
      },
      set(value) {
        this.$store.commit("setDialogAssignRoleUsers", value);
      },
    },
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.$store.commit("setLoadingState", true);
      await this.$store
        .dispatch("loadRoles")
        .then(() => this.$store.commit("setLoadingState", false));
    },

    showItem(item) {
      this.$router.push({ name: "RoleView", params: { roleUuid: item.uuid } });
    },
    editItem(item) {
      // console.log(item)
      this.editItemData = item;
      this.createRoleBool = true;
      this.dialogTitle = "Edit";
    },
    openCreateRoleDialog() {
      this.editItemData = {};
      this.createRoleBool = true;
      this.dialogTitle = "Create";
    },
    closeCreateUpdateRoleDialog() {
      this.$store.dispatch("loadRoles");
      this.createRoleBool = false;
      let data = {
        uuid: null,
        name: "",
        displayName: "",
        description: "",
      };
      this.editItemData = data;
    },
    deleteItem(item) {
      this.role = {
        uuid: item.uuid,
        roleName: item.roleName,
      };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch("deleteRole", { uuid: this.role.uuid }).then(() => {
        this.role = {};
        this.closeDelete();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.role = {};
      });
    },
    assignRoleUsers(item) {
      this.role = {
        uuid: item.uuid,
        displayName: item.displayName,
      };
      this.dialogAssignRoleUsers = true;
    },
    async openAssignRolesDialog() {
      this.role = {};
      this.dialogAssignRoleUsers = true;
    },
  },
};
</script>

<style></style>
