<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="createRoleBool"
        transition="dialog-bottom-transition"
        max-width="600"
        persistent
        :dark="$store.getters.getNightMode"
        style="z-index: 9"
      >
        <template>
          <v-card class="border-0">
            <v-toolbar
              color="primary"
              dark
              :class="[
                'mb-10','text--white'
              ]"
              >{{ DialogTitle }} Role Dialog</v-toolbar
            >
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        prepend-icon="mdi-account"
                        v-model="role.roleName"
                        label="Role Name*"
                        required
                        dense
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        prepend-icon="mdi-script"
                        v-model="role.roleDescription"
                        label="Role Description"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-autocomplete
                        chips
                        clearable
                        deletable-chips
                        multiple
                        small-chips
                        v-model="role.permissionList"
                        :items="permissions"
                        item-text="permissionDisplayName"
                        label="Permissions"
                        item-value="uuid"
                        hide-selected
                        prepend-icon="mdi-lock"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                color="success"
                elevation="0"
                class="rounded-lg"
                dense
                text
                @click="createUpdateRole()"
                ><v-icon small>mdi-plus</v-icon>Save</v-btn
              >
              <v-btn
                color="error"
                elevation="0"
                class="rounded-lg"
                dense
                text
                @click="createRoleBool = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "role-create",
  props: ["EditItemData", "DialogTitle"],
  watch: {
    EditItemData(newValues) {
      // console.log("NEW", newValues);
      this.role.roleName = Object.hasOwn(newValues, "roleName") ? newValues.roleName : "";
      this.role.roleDescription = Object.hasOwn(newValues, "roleDescription") ? newValues.roleDescription : "";
      this.role.uuid = Object.hasOwn(newValues, "uuid") ? newValues.uuid : "";
      this.role.roleDisplayName = Object.hasOwn(newValues, "roleDisplayName")
        ? newValues.roleDisplayName
        : "";
      this.role.permissionList = Object.hasOwn(newValues, "permission")
        ? newValues.permission
        : "";

      // console.log("OBTAINED Role : ",this.role);
    },
  },
  data: () => ({
    role: {
      roleName: "",
      uuid: "",
      roleDescription: "",
      permissionList: [],
    },
    valid: false,
  }),
  computed: {
    createRoleBool: {
      get() {
        return this.$store.getters.getCreateRoleBool;
      },
      set(value) {
        this.$store.commit("setCreateRoleBool", value);
      },
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
  },
  mounted() {
    this.$store.dispatch("loadAllPermissions");
  },
  methods: {
    async createUpdateRole() {
      if (this.role.uuid?.length == 0) {
        delete this.role.uuid;
      }

      // console.log(this.role);
      await this.$store
        .dispatch("createUpdateRole", this.role)
        .then(async () => {
          await this.$store
            .dispatch("loadRoles")
            .then(() => (this.createRoleBool = false));
        });
    },
  },
};
</script>

<style></style>
